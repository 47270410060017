import React from "react"

const HatenaShareIcon = ({ size,round }) => {
  return (
    <span style=
    {
      {
        display:"block",
        fontWeight:"bold",
        fontSize: (size/2.0)+"px",
        textAlign:"center",
        textDecoration:"none",
        width:size+"px",
        height:size+"px",
        lineHeight:size+"px",
        color:"white",
        background:"#00a5e0",
        borderRadius:round ? "50%":"0%",
        marginBottom:"4.8px"
      }
    }>B!</span>
  )
}

export default HatenaShareIcon
