import React from "react"
import config from "../../../customize"
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  LinkedinShareButton,
  LineShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  LinkedinIcon,
  LineIcon,
} from "react-share"
import HatenaShareButton from "./Buttons/HatenaShareButton.ts"
import HatenaShareIcon from "./Icons/HatenaIcon.js"
import MisskeyShareButton from "./Buttons/MisskeyShareButton.ts"
import MisskeyShareIcon from "./Icons/MisskeyIcon.js"
import MastodonShareButton from "./Buttons/MastodonShareButton.ts"
import MastodonShareIcon from "./Icons/MastodonIcon.js"
import XShareButton from "./Buttons/XShareButton.ts"
import XIcon from "./Icons/XIcon.js"
import BlueskyShareButton from "./Buttons/BlueskyShareButton.ts"
import BlueskyShareIcon from "./Icons/BlueskyIcon.js"

import "./styles.scss"

const ShareButtons = ({ location, title }) => {
  
  //末尾の「 | 饅頭遣いのおもちゃ箱」を消す
  title = title.replace(" | 饅頭遣いのおもちゃ箱", "")

  return (
    <div className="share-buttons-wrap">
      {config.shareButtons.email && (
        <EmailShareButton url={location} title={title}>
          <EmailIcon round size={32} />
        </EmailShareButton>
      )}
      {config.shareButtons.x && (
        <XShareButton url={location} title={title}>
          <XIcon round size={32} />
        </XShareButton>
      )}
      {config.shareButtons.Bluesky && (
        <BlueskyShareButton url={location} title={title}>
          <BlueskyShareIcon round size={32} />
        </BlueskyShareButton>
      )}
      {config.shareButtons.Misskey && (
        <MisskeyShareButton url={location} title={title}>
          <MisskeyShareIcon round size={32} />
        </MisskeyShareButton>
      )}
      {config.shareButtons.Mastodon && (
        <MastodonShareButton url={location} title={title}>
          <MastodonShareIcon round size={32} />
        </MastodonShareButton>
      )}
      {config.shareButtons.facebook && (
        <FacebookShareButton url={location} title={title}>
          <FacebookIcon round size={32} />
        </FacebookShareButton>
      )}
      {config.shareButtons.reddit && (
        <RedditShareButton url={location} title={title}>
          <RedditIcon round size={32} />
        </RedditShareButton>
      )}
      {config.shareButtons.linkedIn && (
        <LinkedinShareButton url={location} title={title}>
          <LinkedinIcon round size={32} />
        </LinkedinShareButton>
      )}
      {config.shareButtons.Hatena && (
        <HatenaShareButton url={location} title={title}>
          <HatenaShareIcon round size={32} />
        </HatenaShareButton>
      )}
      {config.shareButtons.Line && (
        <LineShareButton url={location} title={title}>
          <LineIcon round size={32} />
        </LineShareButton>
      )}
    </div>
  )
}

export default ShareButtons
