import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import mediumZoom from "medium-zoom"
import storage from "local-storage-fallback"
import { isMobile } from "react-device-detect"
import { setThemeVars } from "../../../util/theme-helper"
import customize,{ comments } from "../../../../customize"
import configStyles from "../../../../customize-styles"
import Layout from "../../Layout"
import Hr from "../../Hr"
import SEO from "../../SEO"
import {
  FacebookComments,
  DisqusComments,
  UtterancesComments,
} from "../../Comments"
import { theme } from "../../Shared/styles-global"
import ShareButtons from "../../ShareButtons"
import ChevronRight from "../../../../_assets/icons/chevron-right.svg"
import {
  Primary,
  Danger,
  Warning,
  Success,
  Info,
  Collapsable,
  U,
  Index,
  ContactForm,
  NiconicoCard,
  NiconicoPlayer,
  AmazonCard,
  Address,
  Flex,
  Download,
  DLsiteCard,
  RandomFlex,
  CeVIOCards,
  VOICEPEAKCards,
  AIVOICECards,
  VOICEROIDCards,
  CoeAvatarCards,
  YMM4Plugins,
  YMM4Screenshot,
} from "../../MdxComponents"
import Github from "../../Github"
import GoogleAdSenseInArticle from "../../GoogleAdSense/Article"
import ClientOnly from "../../../hooks/ClientOnly"
import {Link} from "gatsby"

class PostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.utterancesRef = React.createRef()
    this.state = {
      location: "",
      script: undefined,
      texts: [],
    }
  }

  componentDidMount() {
    this.setState({ location: window.location.href })
    if (isMobile) {
      this.moveAnchorHeadings()
    }
    this.zoomImages()
    if (comments.facebook.enabled) {
      this.registerFacebookComments()
    }
    if (comments.utterances.enabled && comments.utterances.repoUrl) {
      this.registerUtterancesComments(comments.utterances.repoUrl)
    }
  }

  componentDidUpdate() {
    if (window.FB) {
      window.FB.XFBML.parse()
    }
  }

  registerUtterancesComments = repo => {
    // Register utterances if it exists
    if (this.utterancesRef.current) {
      const script = document.createElement("script")
      script.src = "https://utteranc.es/client.js"
      script.async = true
      script.crossOrigin = "anonymous"
      script.setAttribute("repo", repo)
      script.setAttribute("issue-term", "pathname")
      script.setAttribute("label", "blog-comment")
      script.setAttribute(
        "theme",
        `${theme.curTheme === "dark" ? "github-dark" : "github-light"}`
      )
      this.utterancesRef.current.appendChild(script)
    }
  }

  registerFacebookComments = () => {
    // Unregister if already exists
    this.unregisterFacebookComments()
    // Register facebook comments sdk
    const script = document.createElement("script")
    script.src = "https://connect.facebook.net/en_US/sdk.js"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    // Set as state to unmount script
    this.setState({ script: script })
    document.body.appendChild(script)
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: comments.facebook.appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v6.0",
      })
    }
  }

  unregisterFacebookComments = () => {
    // Unmount script and comments div
    if (this.state.script) {
      document.body.removeChild(this.state.script)
      const fbRoot = document.getElementById("fb-root")

      if (fbRoot) {
        document.body.removeChild(fbRoot)
      }

      this.setState({ script: undefined })
    }
  }

  componentWillUnmount() {
    this.unregisterFacebookComments()
  }

  zoomImages = () => {
    const targetGatsbyImg = "gatsby-resp-image-image"
    const images = Array.from(document.querySelectorAll("img"))
    const ignoreImages = Array.from(document.querySelectorAll("a img, img.ignore-zoom"));


    const filteredImages = []
    for (let i = 0; i < images.length; i++) {
      const img = images[i]
      const isIgnore = ignoreImages.includes(img)
      if (!isIgnore) {
        // Set maximum width/height to non-gatsby images
        if (!img.classList.contains(targetGatsbyImg)) {
          img.classList.add("img-not-gatsby-remark")
        }
        filteredImages.push(img)
      }
    }

    console.log(filteredImages.length);

    let mediumZoomBgColor = ""
    const savedTheme = JSON.parse(storage.getItem("theme")) || "light"
    mediumZoomBgColor =
      savedTheme.mode === "light" ? theme.bgColorLight : theme.bgColorDark

    // Apply medium zoom to images
    mediumZoom(filteredImages, {
      margin: 24,
      background: mediumZoomBgColor,
    })
  }

  // Move anchor headings to the right side on mobile
  moveAnchorHeadings = () => {
    const target = ".anchor-heading"
    const anchors = Array.from(document.querySelectorAll(target))
    anchors.forEach(anchor => {
      anchor.parentNode.appendChild(anchor)
      anchor.classList.add("after")
      anchor.classList.remove("before")
    })
  }

  // Toggle loading for changing copy texts
  toggleLoading = text => {
    this.setState(prevState => {
      const updatedTexts = [...prevState.texts]
      updatedTexts.forEach(t => {
        if (t.id === text.id) {
          t.loadingChange = !t.loadingChange
        }
      })
      return {
        texts: updatedTexts,
      }
    })
  }

  render() {
    const post = this.props.data.mdx
    const isAboutPage = post.fields.slug.includes("/about")

    // Customize markdown component
    const mdxComponents = {
      "ul.li": ({ children }) => {
        return (
          <li>
            <span className="icon-wrap">
              <ChevronRight className="icon-chevron-right" />
            </span>
            <span className="ul-children">{children}</span>
          </li>
        )
      },
      /*//以下を有効にすると、liの中にimgがある場合、クリックして拡大・lazy読み込みが機能しない
      "ol.li": ({ children }) => {
        return (
          <li>
            <span>{children}</span>
          </li>
        )
      },
      */
      hr: () => <Hr widthInPercent="100" verticalMargin="0.8rem" />,
      // Use the below components without having to import in *.mdx
      Primary,
      Danger,
      Warning,
      Success,
      Info,
      Collapsable,
      U,
      Index:()=><Index slug={post.fields.slug}/>,
      ContactForm,
      NiconicoCard,
      NiconicoPlayer,
      AmazonCard,
      Address,
      Flex,
      Download,
      DLsiteCard,
      RandomFlex,
      CeVIOCards,
      VOICEPEAKCards,
      AIVOICECards,
      VOICEROIDCards,
      CoeAvatarCards,
      YMM4Plugins,
      YMM4Screenshot,
    }

    return (
      <Layout sidebar={true} slug={post.fields.slug}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        
        <StyledHTML className="post-html">
          {!isAboutPage && (
            <div style={{marginBottom:"1rem"}}>
              <h1 className="post-title" style={{float:"left", marginBottom:"0rem", marginRight:"1rem"}}>{post.frontmatter.title}</h1>
              <div style={{float:"right", marginTop:"0.5rem"}}>
                <ShareButtons location={this.state.location} title={post.frontmatter.title+" | "+customize.title}/>
              </div>
              <div style={{clear:"both"}}/>
            </div>
          )}

          {/* urlに/ymm3/[faq, help, tips, tutorial]/が含まれている場合、警告文を表示する */}
          {(/\/ymm3\/(faq|help|tips|tutorial)\//.test(post.fields.slug)) && (
            <div className="md-warning">
              <p>
                こちらは旧バージョンのYMM3に関するページです。<br/>
                最新の<Link to="/ymm4/">YMM4</Link>については<Link to="/ymm4/faq/">こちら</Link>をご確認ください。
              </p>
            </div>
          )}

          {/* Render mdx */}
          <MDXProvider components={mdxComponents}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
          
          <ClientOnly>
            <GoogleAdSenseInArticle/>
          </ClientOnly>

          {/* 記事下の共有リンク等 */}
          <FooterContainer>
            <ShareButtons location={this.state.location} title={post.frontmatter.title+" | "+customize.title}/>
            {/*<LinkEdgePosts pageContext={this.props.pageContext} />*/}
            {comments.facebook.enabled && (
              <FacebookComments
                location={this.state.location}
                reload={this.registerFacebookComments}
              />
            )}
            {comments.disqus.enabled && comments.disqus.shortName && (
              <DisqusComments
                shortName={comments.disqus.shortName}
                location={this.state.location}
              />
            )}
            {comments.utterances.enabled && comments.utterances.repoUrl && (
              <UtterancesComments innerRef={this.utterancesRef} />
            )}
            <Hr/>
            <Github file={post.fileAbsolutePath}/>
          </FooterContainer>
        </StyledHTML>

      </Layout>
    )
  }
}

export const postQuery = graphql`
  query BlogPostByPath($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        tags
      }
      fileAbsolutePath
    }
  }
`

export default PostTemplate

const listColor = () =>
setThemeVars(
  "steelblue",
  "#455765"
);
//順序月リストの●はwordpress swell themeを参考に実装した
//https://swell-theme.com/function/4855/
const StyledHTML = styled.div`
  word-wrap: break-word;
  padding: 1rem 3rem;
  font-family: ${configStyles.fontMain + configStyles.fontsBackUp};
  margin-top: 1rem;
  font-size: 105%;
  overflow-wrap: anywhere;
  h1 {
    margin-top: 2.5rem;
  }

  .post-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 1.3rem;
    margin-left:1rem;
  }

  h4 {
    margin-top: 1rem;
    margin-left:1rem;
  }

  h5 {
    margin-top: 0.8rem;
    margin-left:1rem;
  }

  h6 {
    margin-top: 0.6rem;
    margin-left:1rem;
  }

  p {
    margin-top: 0.9rem;
    line-height: 1.75rem;
  }


  blockquote {
    padding: 0.3rem 1rem;
    margin: 0.5rem 0;

    > p {
      margin-top: 0.5rem;
    }

    > blockquote {
      border-left: none;
      font-size: 1.2rem;
      > blockquote {
        font-size: 1.3rem;
      }
    }
  }

  a {
    color:${() => setThemeVars("steelblue", "lightskyblue")};
  }

  ol {
    margin: 1.5rem 1.2rem 0rem  1.2rem;
    counter-reset: item;
    list-style-type: none;
    ol:before{
      content: "";
      color: transparent;
      width: 3rem;
      height: 2rem;
      margin-top: -1.35rem;
      margin-right:-3rem;
      border-left: 3px solid ${listColor};
      border-bottom: 3px solid ${listColor};
      position: relative;
      float: left;
      left: -3.38rem;
    }
    >li {
      margin-left: 0rem;
      padding-left: 2rem;
      padding-top: 0rem;
      padding-bottom: 2rem;
      border-left: 3px solid ${listColor};
    }
    >li:before {
      counter-increment: item;
      content: counter(item);
      color: white;
      width: 2.5rem;
      height: 2rem;
      margin-right: -2.5rem;
      padding-top: 0.5rem;
      text-align: center;
      background-color: ${listColor};
      border-radius: 50%;
      position: relative;
      float:left;
      left: -3.35rem;
      top: -0.5rem;
    }
    li:last-child {
      border-left: 3px solid transparent;
    }
  }

  ul {
    list-style: none;
    margin: 0.5rem 0.3rem;
    li {
      display: flex;
      justify-content: flex-start;
      margin: 0.5rem 0;
      /* Custom list for ul */
      .icon-wrap {
        svg.icon-chevron-right {
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.5rem;
          fill: ${() =>
            setThemeVars(
              configStyles.fontColorLight,
              configStyles.fontColorDark
            )};
        }
      }
      span.ul-children {
        width: 100%;
        & > p:first-child {
          display: inline;
        }
      }
    }
  }

  pre {
    font-family: inherit;
  }

  img {
    margin: 0.35rem 0;
    max-width:500px;
    width:auto;
  }

  .gatsby-resp-image-wrapper {
    margin: 0.5rem 0;
  }

  @media (max-width: 500px) {
    padding: 0.5rem 1rem;

    .post-title {
      font-size: 2rem;
    }

    ul,
    ol {
      margin-right: 1rem;
    }
    ol{
      ol:before{
        left: -3.4rem;
      }
    }
  }
  em{
    font-style:normal;
    font-weight:normal;
    font-size: 1rem;
    padding: 0.20em 0.25rem;
    margin: 0 0.25rem;
    border-radius: 2px;
    background: ${()=>setThemeVars( "#f1f1f1","#3b3948")};
  }
  .md-note{
    position: relative;
    border-left: 3px solid rgb(23, 162, 184);
    margin: 2rem 1rem;
    border-radius: 5px;
    padding: 2rem 1.5rem;
    background: ${() => setThemeVars("#daf1f4", "#2e5d64")};
    p:first-child{
      margin-top: 0;
    }
    a {
      color:${() => setThemeVars("steelblue", "lightskyblue")};
    }
  }
  .md-warning{
    position: relative;
    border-left: 3px solid #ffc107;
    margin: 2rem 1rem;
    border-radius: 5px;
    padding: 2rem 1.5rem;
    background: ${() => setThemeVars("#f8f1e4", "#796733")};
    p:first-child{
      margin-top: 0;
    }
    a {
      color:${() => setThemeVars("steelblue", "lightskyblue")};
    }
  }
`
const FooterContainer = styled.div`
  padding: 0.1rem 0rem;
`
