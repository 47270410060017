import assert from 'react-share/lib/utils/assert';
import createShareButton from 'react-share/lib/hocs/createShareButton';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';

function misskeyLink(url: string, { title }: { title?: string }) {
  assert(url, 'misskey.url');

  return (
    'https://misskey-hub.net/share/' +
    objectToGetParams({
      url:url,
      text: title,
    })
  );
}

const MisskeyShareButton = createShareButton<{ title?: string }>(
  'misskey',
  misskeyLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 500,
    windowHeight: 600,
  },
);

export default MisskeyShareButton;