import assert from 'react-share/lib/utils/assert';
import createShareButton from 'react-share/lib/hocs/createShareButton';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';

function convertURL(url) {
  const baseURL = "https://b.hatena.ne.jp/entry/s/";
  const newURL = url.replace(/^https?:\/\//, baseURL);
  return newURL;
}

function hatenaLink(url: string, { title }: { title?: string }) {
  assert(url, 'hatena.url');
  
  return (convertURL(url));
}

const HatenaShareButton = createShareButton<{ title?: string }>(
  'hatena',
  hatenaLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 500,
    windowHeight: 700,
  },
);

export default HatenaShareButton;