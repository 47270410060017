import React from "react"
import Mi from "../../../../_assets/icons/mi.svg"

const MisskeyShareIcon = ({ size,round }) => {
  return (
    <span style=
    {
      {
        display:"block",
        fontWeight:"bold",
        fontSize: (size/2.0)+"px",
        textAlign:"center",
        textDecoration:"none",
        width:size+"px",
        height:size+"px",
        lineHeight:size+"px",
        color:"white",
        background: "linear-gradient(90deg,#86b300,#4ab300)",
        borderRadius:round ? "50%":"0%",
        marginBottom:"4.8px",
      }
    }>
      <Mi width={size/2.0} height={size} fill="white" />
    </span>
  )
}

export default MisskeyShareIcon
