import assert from 'react-share/lib/utils/assert';
import createShareButton from 'react-share/lib/hocs/createShareButton';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';

function blueskyLink(url: string, { title }: { title?: string }) {
  assert(url, 'bsky.app');
  return (
    'https://bsky.app/intent/compose' +
    objectToGetParams({
      text: title + "\n" + url,
    })
  );
}

const BlueskyShareButton = createShareButton<{ title?: string }>(
  'bluesky',
  blueskyLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 650,
    windowHeight: 400,
  },
);

export default BlueskyShareButton;