import React, {useState, useEffect} from "react"
import styled from "styled-components"

export default class GoogleAdSenseInArticle extends React.Component {
  componentDidMount() {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  };

  render(){
    return (
      <div>
        <Ins
          className="adsbygoogle"
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-0586345579615305"
          data-ad-slot="8289541187"
        />
      </div>
    )
  }
}

const Ins = styled.ins({
  display: "block",
  margin: "1.5rem 0"
})