import React from "react"
import X from "../../../../_assets/icons/x.svg"

const HatenaShareIcon = ({ size,round }) => {
  return (
    <span style=
    {
      {
        display:"block",
        fontWeight:"bold",
        fontSize: (size/2.0)+"px",
        textAlign:"center",
        textDecoration:"none",
        width:size+"px",
        height:size+"px",
        lineHeight:size+"px",
        color:"white",
        background:"black",
        borderRadius:round ? "50%":"0%",
        marginBottom:"4.8px"
      }
    }>
      <X width={size/2} height={size} fill="white"/>
    </span>
  )
}

export default HatenaShareIcon
