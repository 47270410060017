import React from "react"
import styled from "styled-components"
import config from "../../../customize-styles"
import { setThemeVars } from "../../util/theme-helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub } from "@fortawesome/free-brands-svg-icons"

const GithubEditLink = ({file}) => {
  if(!file)
    return <StyledDiv/>;
  return (
    <StyledDiv>
        <a href={"https://github.com/manju-summoner/manjubox.posts/tree/master/"+file.substring("D:/repos/manjubox/_posts".length)}
           target="_blank"
           rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            className="icon-fab fa-github"
            icon={faGithub}
          />
          <span>
            Edit on Github
          </span>
        </a>
    </StyledDiv>
  )
}

export default GithubEditLink

const StyledDiv = styled.span`
  a{
    color:${()=>setThemeVars( config.fontColorLight,config.fontColorDark)} !important;
  }

  span, .fa-github{
    display: inline-block;
    vertical-align: middle; 
    margin:-0.2rem 0 0 0;
  }
  .fa-github{
    font-size:0.9em;
    margin:-0.15rem 0.25rem 0 0;
  }
  span{
    font-size:0.8em;
  }
  
`
